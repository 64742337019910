/** @jsxImportSource theme-ui */
import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import {
  Box,
  Container,
  Flex,
  Themed,
  Text,
  Link as ThemeUILink,
  Grid,
  Heading,
  Divider,
} from "theme-ui";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ThemedMarkdown from "../components/themed-markdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComments,
  faEarListen,
  faHandHoldingHeart,
  faHeadphones,
  faPuzzlePiece,
  faStar,
  faStarHalfStroke,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { useResponsiveValue } from "@theme-ui/match-media";
import OnlineCalendar from "../components/online-calendar";

type ReviewStarsProps = {
  stars: number;
};

const ReviewStars = ({ stars }: ReviewStarsProps) => {
  let starIcons: React.ReactNode[] = [];
  for (let star = 0; star < Math.floor(stars); star++) {
    starIcons.push(
      <FontAwesomeIcon key={star} icon={faStar} size={"1x"} sx={{ color: "primary" }} />
    );
  }
  if (stars > Math.floor(stars)) {
    starIcons.push(
      <FontAwesomeIcon key={4} icon={faStarHalfStroke} size={"1x"} sx={{ color: "primary" }} />
    );
  }
  return <React.Fragment>{starIcons}</React.Fragment>;
};

type WaypointProps = {
  icon: IconDefinition;
  wayname: string;
  waymarkdown: string;
};

const Waypoint = ({ icon, wayname, waymarkdown }: WaypointProps) => {
  const iconSize: "2x" | "3x" | "4x" = useResponsiveValue(["2x", "3x", "4x"]);
  return (
    <Flex my={[2, 3, 4]}>
      <Box sx={{ minWidth: 50, width: 50 }}>
        <FontAwesomeIcon icon={icon} size={iconSize} sx={{ color: "primary" }} fixedWidth />
      </Box>
      <Box ml={[3, 4, 5]}>
        <Heading as="h3" variant="styles.h3" sx={{ mt: 0 }}>
          {wayname}
        </Heading>
        <ThemedMarkdown html={waymarkdown} />
      </Box>
    </Flex>
  );
};

type ReviewProps = {
  fattext: string;
  text: string;
  stars: "4.5" | "5";
  customer: string;
  link: string;
};

const Review = ({ fattext, text, stars, customer, link }: ReviewProps) => {
  return (
    <Flex
      sx={{
        flexDirection: "column",
        mb: [3, 3, 4],
      }}
    >
      <Flex sx={{ backgroundColor: "gray.2", borderRadius: "6px", mb: 2, flex: "1 1 auto" }}>
        <blockquote cite={link}>
          <Text>
            <strong>{fattext}</strong> {text}
          </Text>
        </blockquote>
      </Flex>
      <Flex px={1}>
        <Box sx={{ flex: "2 1 0" }}>
          <ReviewStars stars={parseFloat(stars)} />
        </Box>
        <Box sx={{ flex: "3 1 0", textAlign: "right" }}>
          <strong sx={{ color: "textMuted" }}>{customer},</strong>{" "}
          <ThemeUILink href={link}>Bewertung</ThemeUILink>
        </Box>
      </Flex>
    </Flex>
  );
};

export const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const splashImageData = getImage(frontmatter.hero.image);
  const teamImageData = getImage(frontmatter.teampicture);
  const historyImageData = getImage(frontmatter.history.image);
  const signatureImageData = getImage(frontmatter.history.signature);
  const topImageDatas = frontmatter.topstore.map((store) => getImage(store.image));
  return (
    <Layout seo={{ title: frontmatter.title, description: frontmatter.seoDescription }}>
      <Box sx={{ position: "relative" }}>
        <GatsbyImage
          sx={{ width: "100vw", zIndex: -100 }}
          image={splashImageData}
          alt={frontmatter.hero.alttext}
        />
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: [undefined, undefined, "absolute"],
            bottom: 0,
          }}
        >
          <Container sx={{ height: "100%" }}>
            <Flex
              sx={{
                height: "100%",
                flexDirection: ["column", "column", "row"],
                alignItems: "center",
                mt: [4, 4, 0],
              }}
            >
              <Box sx={{ flex: "3 3 0", display: ["none", "none", "block"] }}></Box>
              <Box sx={{ flex: "2 2 0" }}>
                <Box
                  sx={{
                    backgroundColor: "white",
                    opacity: 0.95,
                    padding: [2, 3, 4, 5],
                    width: ["100%", "100%", "max-content"],
                    border: "1px solid",
                    borderRadius: 4,
                    borderColor: "#ea428d",
                    boxShadow: "0px 0px 20px #999999",
                  }}
                >
                  <OnlineCalendar />
                </Box>
              </Box>
            </Flex>
          </Container>
        </Box>
      </Box>
      <Container mt={[4, 4, 4]}>
        <Heading as="h1" variant="styles.h2" mb={4}>
          Weil gutes Hören glücklich macht
        </Heading>
        <Flex sx={{ flexDirection: ["column", "column", "row"], alignItems: "center" }}>
          <Box sx={{ flex: "1 1 0", mr: 3, display: ["none", "none", "inherit"] }}>
            <GatsbyImage image={historyImageData} alt={frontmatter.history.alttext} />
          </Box>
          <Flex
            sx={{
              flex: "1 1 0",
              ml: 3,
              alignItems: ["center", "center", "flex-start"],
              flexDirection: "column",
            }}
          >
            <ThemedMarkdown html={frontmatter.history.text} />
            <Box sx={{ display: ["inherit", "inherit", "none"] }}>
              <GatsbyImage image={historyImageData} alt={frontmatter.history.alttext} />
            </Box>
            <GatsbyImage image={signatureImageData} alt="Signatur" />
          </Flex>
        </Flex>
      </Container>
      <Container mt={[4, 5, 6]} sx={{ display: ["inherit", "inherit", "inherit"] }}>
        <Heading as="h2" variant="styles.h2" mb={4}>
          Ihr OTThören Team Ausgezeichnet
        </Heading>
        <GatsbyImage image={teamImageData} alt="Ott Teamfoto" />
        <Flex sx={{ flexFlow: "nowrap", mt: 4, justifyContent: "center" }}>
          {topImageDatas.map((data, i) => (
            <Box sx={{ flex: "1 1 0", maxWidth: "220px", mx: 3 }}>
              <GatsbyImage key={i} image={data} alt={`top akustiker ${i}`} />
            </Box>
          ))}
        </Flex>
      </Container>
      <Container mt={[5, 5, 6]}>
        <Heading as="h2" variant="styles.h2" mb={4}>
          Ihr Weg zum besseren Hören
        </Heading>
        <Flex sx={{ flexDirection: "column" }}>
          <Waypoint wayname="Hörtests" icon={faHeadphones} waymarkdown={frontmatter.way.testing} />
          <Waypoint
            wayname="Persönliche Beratung"
            icon={faComments}
            waymarkdown={frontmatter.way.consultation}
          />
          <Waypoint
            wayname="Ohr-Abformung"
            icon={faPuzzlePiece}
            waymarkdown={frontmatter.way.fitting}
          />
          <Waypoint
            wayname="Hörsystem"
            icon={faEarListen}
            waymarkdown={frontmatter.way.hearingsystem}
          />
          <Waypoint
            wayname="Service inklusive"
            icon={faHandHoldingHeart}
            waymarkdown={frontmatter.way.service}
          />
        </Flex>
      </Container>
      <Container my={[4, 5, 6]}>
        <Heading as="h2" variant="styles.h2">
          Wir begeistern Kunden
        </Heading>
        <Grid gap={[3, 3, 4]} columns={[1, 1, 2, 3]} my={[3, 4, 5]}>
          {frontmatter.reviews.map((r) => (
            <Review key={r.link} {...r} />
          ))}
        </Grid>
      </Container>
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        seoDescription
        hero {
          image {
            childImageSharp {
              gatsbyImageData(quality: 92, layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
          alttext
        }
        history {
          image {
            childImageSharp {
              gatsbyImageData(width: 640, quality: 92, layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
          signature {
            childImageSharp {
              gatsbyImageData(width: 500, layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
          alttext
          text
        }
        teampicture {
          childImageSharp {
            gatsbyImageData(quality: 92, layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        topstore {
          image {
            childImageSharp {
              gatsbyImageData(quality: 92, layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
        }
        way {
          testing
          consultation
          fitting
          hearingsystem
          service
        }
        reviews {
          fattext
          text
          stars
          customer
          link
        }
      }
    }
  }
`;
