/** @jsxImportSource theme-ui */
import React, { useState, useEffect, useRef } from "react";
import ky from "ky";
import { navigate } from "gatsby";
import {
  Flex,
  Box,
  Heading,
  Divider,
  Select,
  Button,
  Label,
  Field,
  useThemeUI,
  Spinner,
  Text,
} from "theme-ui";
import {
  format,
  add,
  isBefore,
  startOfToday,
  setHours,
  setMinutes,
  compareAsc,
  isWeekend,
} from "date-fns";
import { de } from "date-fns/locale";
import FocusTrap from "focus-trap-react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { logHttpErrorDetails } from "../lib/utils";

// const AvailableTimes: [number, number][] = [
//   [9, 0],
//   [9, 30],
//   [10, 0],
//   [10, 30],
//   [11, 0],
//   [11, 30],
//   [14, 0],
//   [14, 30],
//   [15, 0],
//   [15, 30],
//   [16, 0],
//   [16, 30],
//   [17, 0],
// ];
type StoreSelectType = "abensberg" | "kelheim" | "langquaid" | "mainburg" | "wolnzach";
type BookingSelectType =
  | "todo"
  | "service"
  | "beratung"
  | "reparatur"
  | "anpassung"
  | "hoertest"
  | "gehoerschutz";
type OnlineCalendarProps = {
  sxpass?: object;
};
type CustomDayPickerProps = {
  date: Date;
  setDate: (d: Date) => void;
  sxpass?: object;
};

const formatAvailableTime = ([h, m]) => {
  const t = new Date();
  t.setHours(h);
  t.setMinutes(m);
  return format(t, "HH:mm");
};

const CustomDayPicker = ({ date, setDate, sxpass }: CustomDayPickerProps) => {
  const tomorrow = add(startOfToday(), { days: 1 });
  const future90 = add(startOfToday(), { days: 90 });
  const [selectDate, setSelectDate] = useState(false);
  const { theme } = useThemeUI();
  const calendarField = useRef<HTMLInputElement>(null);

  return (
    <Box sx={{ position: "relative", ...sxpass }}>
      <Field
        label="Datum"
        name="calendar-date"
        id="calendar-date"
        value={format(date, "dd.MM.yyyy")}
        onSelect={() => setSelectDate(true)}
        onChange={() => {}}
        ref={calendarField}
        sx={{
          ":focus": {
            borderColor: "primary",
            boxShadow: (t) => `0 0 0 1px ${t.colors.primary}`,
            outline: "none",
          },
        }}
      />
      {selectDate && (
        <FocusTrap
          active
          focusTrapOptions={{
            initialFocus: false,
            allowOutsideClick: true,
            clickOutsideDeactivates: true,
            returnFocusOnDeactivate: false,
            onDeactivate: () => setSelectDate(false),
          }}
        >
          <Box
            sx={{
              background: "background",
              position: "absolute",
              mt: 1,
              border: "3px solid",
              borderColor: "backgroundOff",
              borderRadius: 5,
            }}
          >
            <DayPicker
              mode="single"
              locale={de}
              selected={date}
              onSelect={(sel) => {
                setDate(sel || date || tomorrow);
                setSelectDate(false);
                calendarField.current?.blur();
              }}
              disabled={(day: Date) => {
                return isBefore(day, tomorrow) || isWeekend(day);
              }}
              fromMonth={tomorrow}
              toDate={future90}
              modifiersStyles={{
                selected: { backgroundColor: theme.colors.primary },
              }}
            />
          </Box>
        </FocusTrap>
      )}
    </Box>
  );
};

const OnlineCalendar = ({ sxpass }: OnlineCalendarProps) => {
  const tomorrow = add(new Date(), { days: 1 });
  tomorrow.setHours(0, 0, 0, 0);
  const [searching, setSearching] = useState(false);
  const [store, setStore] = useState<StoreSelectType>("abensberg");
  const [booking, setBooking] = useState<BookingSelectType>("todo");
  const [date, setDate] = useState<Date>(tomorrow);
  // const [searchTimeHour, setSearchTimeHour] = useState(AvailableTimes[0][0]);
  // const [searchTimeMinute, setSearchTimeMinute] = useState(AvailableTimes[0][1]);

  const [searchResults, setSearchResults] = useState<Date[]>([]);
  const [error, setError] = useState<null | string>(null);

  const searchCB = async () => {
    setError(null);
    setSearchResults([]);
    if (booking == "todo") {
      setError("Bitte Termintyp auswählen.");
      return;
    }

    setSearching(true);
    const searchDate = setMinutes(setHours(date, 8), 0);
    let data: any = null;
    try {
      data = await ky
        .get(
          `${process.env.GATSBY_CALENDAR_API_URL}/${process.env.BACKEND_COMPANY_ID}/query_times`,
          {
            searchParams: {
              store: store,
              date: searchDate.getTime() / 1000,
              booking: booking,
            },
          }
        )
        .json();
      setSearching(false);
    } catch (error) {
      console.log(error);
      logHttpErrorDetails(error);
      setError("Verbindungsfehler zum Online Kalender. Bitte versuchen Sie es später nochmal.");
      setSearching(false);
      return;
    }

    let dates = data?.dates || [];
    if (dates.length == 0) {
      setError("Keinen freien Termin gefunden. Bitte wählen Sie einen anderen Tag.");
    }
    dates = dates.map((d) => new Date(d * 1000));
    dates.sort((date1, date2) => compareAsc(date1, date2));
    setSearchResults(dates);
  };

  const submit = (value: Date) => {
    navigate("/termin-buchen", {
      state: {
        store: store,
        date: value,
        appointType: booking,
      },
    });
  };

  return (
    <Box
      as="form"
      onSubmit={(e) => e.preventDefault()}
      sx={{
        width: ["100%", "100%", "240px", "320px"],
        maxWidth: ["100%", "100%", "240px", "320px"],
        ...sxpass,
      }}
    >
      <Heading as="h2" variant="styles.h4">
        Termin buchen
      </Heading>
      <Divider />
      <Box my={3}>
        <Label htmlFor="calendar-store">Filiale</Label>
        <Select
          name="calendar-store"
          id="calendar-store"
          value={store}
          onChange={(e) => setStore(e.target.value as StoreSelectType)}
        >
          <option value="abensberg">Abensberg</option>
          <option value="kelheim">Kelheim</option>
          <option value="langquaid">Langquaid</option>
          <option value="mainburg">Mainburg</option>
          <option value="wolnzach">Wolnzach</option>
        </Select>
      </Box>
      <Flex my={3} sx={{ gap: 3 }}>
        <CustomDayPicker date={date} setDate={setDate} sxpass={{ flex: "1 1 0" }} />
        <Box sx={{ flex: "1 1 0" }}>
          <Label htmlFor="calendar-booking-type">Termintyp</Label>
          <Select
            name="calendar-booking-type"
            id="calendar-booking-type"
            value={booking}
            onChange={(e) => setBooking(e.target.value as BookingSelectType)}
          >
            <option value="todo"></option>
            <option value="beratung">Beratung (90m)</option>
            <option value="service">Service (30m)</option>
            <option value="reparatur">Reparatur (30m)</option>
            <option value="anpassung">Neue Hörgeräte (60m)</option>
            <option value="hoertest">Hörtest (60m)</option>
            <option value="gehoerschutz">Gehörschutz (30m)</option>
          </Select>
          {/* <Select
            name="calendar-time"
            id="calendar-time"
            onChange={(e) => {
              const [h, m] = AvailableTimes[e.target.value];
              setSearchTimeHour(h);
              setSearchTimeMinute(m);
            }}
          >
            {AvailableTimes.map((a, i) => (
              <option key={i} value={i}>
                {formatAvailableTime(a)}
              </option>
            ))}
          </Select> */}
        </Box>
      </Flex>
      <Flex>
        <Button
          disabled={searching}
          type="button"
          onClick={searchCB}
          variant="cta"
          sx={{ mt: 3, py: 2, flex: "1" }}
        >
          {searching ? <Spinner size={32} variant="styles.spinner" /> : "Termin suchen"}
        </Button>
      </Flex>
      {searchResults.length > 0 && (
        <Box mt={4}>
          <Heading as="h3" variant="styles.h6">
            Termin wählen:
          </Heading>
          <Flex sx={{ flexWrap: "wrap", gap: 3, mt: 3 }}>
            {searchResults.map((sr: Date) => (
              <Button key={sr.getTime()} variant="default" onClick={() => submit(sr)}>
                {format(sr, "HH:mm")}
              </Button>
            ))}
          </Flex>
        </Box>
      )}
      {!!error && (
        <Box mt={2}>
          <Text variant="smallText">{error}</Text>
        </Box>
      )}
    </Box>
  );
};

export default OnlineCalendar;
